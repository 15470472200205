import BarChart from '@amzn/awsui-components-react/polaris/bar-chart';
import { convertAsinProgressData, getTotalAsinNmber } from './helperFuncs';

export default function TrackingBar(props) {
  //ASIN Progress Bar doesn't render intuitively when the item is still in backlog
  //To resolve this we add custom rendering for when total ASIN count is 0.
  const processedAsinCount = getTotalAsinNmber(props);
  if (processedAsinCount === 0) {
    return (
      <BarChart
        series={[
          {
            title: 'Pending Backlog Generation',
            type: 'bar',
            data: [{ x: '', y: 1 }],
            color: '#3184c2',
            valueFormatter: (e) => 'For more information, please check the wiki above.',
          },
        ]}
        xDomain={['']}
        yDomain={[0, 1]}
        errorText="Error loading data."
        height={25}
        hideLegend
        horizontalBars
        i18nStrings={{
          filterPlaceholder: 'Filter data',
        }}
        loadingText="Loading chart"
        recoveryText="Retry"
        stackedBars
        xScaleType="categorical"
        hideFilter={true}
      />
    );
  } else {
    return (
      <BarChart
        series={convertAsinProgressData(props)}
        xDomain={['']}
        yDomain={[0, 1]}
        errorText="Error loading data."
        height={25}
        hideLegend
        horizontalBars
        i18nStrings={{
          filterPlaceholder: 'Filter data',
        }}
        loadingText="Loading chart"
        recoveryText="Retry"
        stackedBars
        xScaleType="categorical"
        hideFilter={true}
      />
    );
  }
}
