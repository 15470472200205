import * as actions from './actions';

export interface ErrorModalState {
  errorMessage: string;
  errorVisible: boolean;
}

export const initialState: ErrorModalState = {
  errorMessage: '',
  errorVisible: false
};

const actionMapper = {
  [actions.SET_ERROR_MESSAGE]: (state, { value }) => {
    return {
      ...state,
      errorMessage: value,
    };
  },
  [actions.SET_ERROR_VISIBLE]: (state, { value }) => {
    return {
      ...state,
      errorVisible: value,
    };
  },
};

export default function createErrorModalReducer(state: ErrorModalState = initialState, action) {
  if (typeof actionMapper[action.type] === 'function') {
    return actionMapper[action.type](state, action);
  }

  return state;
}

export const stateKey = 'errorModal';
