import { AxiosInstance } from 'axios';
import { put, takeLatest, all, select } from 'redux-saga/effects';
import * as actions from './actions';
import { TaskInfo } from './reducer';
import * as selectors from './selectors';
import { createApiClient } from '../../common/apiClient';

async function getDropdownResponse(client: AxiosInstance) {
  const response = await client.get('/getDropdown');

  return response.data;
}

function* getDropdownVals() {
  const client = yield createApiClient();

  try {
    const data = yield getDropdownResponse(client);
    yield put(actions.receiveDropdownVals(data));
  } catch (e) {
    console.error(e);
  }
}

async function getTaskSubmissionRes(client: AxiosInstance, taskInfo: TaskInfo) {
  const payload: any = {
    clientId: taskInfo.clientId,
    taskName: taskInfo.taskName,
    marketplace: taskInfo.marketplace,
    s3Path: taskInfo.s3Location,
    taskType: taskInfo.taskType,
    taskDesc: taskInfo.taskDesc,
    skipDedupeCheck: String(taskInfo.skipCheck),
    tagGranularity: taskInfo.tagGranularity,
    auditorQualification: taskInfo.auditorQualification,
  };

  const response = await client.post('/submitTask', payload);
 
  return response.data;
}

function* submitTaskToApi() {
  const taskInfo = yield select(selectors.getTaskInfo);
  const client = yield createApiClient();

  try {
    const res = yield getTaskSubmissionRes(client, taskInfo);
    if (res.SubmissionResponse.includes('errorMessage')) {
      yield put(actions.receiveSubmissionResFail());
    } else {
      yield put(actions.receiveSubmissionResOk(res));
    }
  } catch (e) {
    console.error(e);
  }
}

export default function* sagas() {
  yield all([
    takeLatest(actions.REQUEST_DROPDOWN_VALS, getDropdownVals),
    takeLatest(actions.SUBMIT_OPERATION, submitTaskToApi),
  ]);
}
