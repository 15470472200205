import * as actions from './actions';

export interface TaskInfo {
  RequesterId: string;
  TaskName: string;
  TaskId: string;
  TaskStatus: string;
  TaskType: string;
  AsinTracking: any;
  BatchInfo: any;
  CreationTime: string;
  Marketplace: string;
}

export interface TaskTrackingState {
  loading: boolean;
  tasks: TaskInfo[];
}

export const initialState: TaskTrackingState = {
  loading: false,
  tasks: [],
};

export interface GetPaginatedTaskInput {
  taskStatus: string,
  lastEvaluatedKey: any,
}

export interface GetPaginatedTaskOutput {
  taskStatus: string,
  lastEvaluatedKey: any,
  taskInfoList: TaskInfo[]
}

const actionMapper = {
  [actions.REQUEST_PAGINATED_TASKS]: (state, { value }) => {
    return {
      ...state,
      loading: true,
    };
  },
  [actions.ERROR_LOADING_TASKS]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [actions.RECEIVE_PAGINATED_TASKS]: (state, { taskInfoList }) => {
    return {
      ...state,
      tasks: [...state.tasks, ...taskInfoList],
      loading: false,
    };
  },
};

export default function taskTrackingTableReducer(state: TaskTrackingState = initialState, action) {
  if (typeof actionMapper[action.type] === 'function') {
    return actionMapper[action.type](state, action);
  }

  return state;
}

export const stateKey = 'taskTrackingTable';
