import Alert from '@amzn/awsui-components-react/polaris/alert';
import { getAppSetting } from '../../config/AppSettings';

const prodIdentifier = 'prod';

export default function BetaHeader() {
  if (!isProdStage()) {
    return (
      <Alert
        dismissAriaLabel="Close alert"
        type="warning"
        header="You are currently on the Beta CTMS website. Any work done here will not be reflected in prod."
      >
        <a href="https://tagman.browse.amazon.dev/">Click here to go to the prod CTMS website</a>
      </Alert>
    );
  }
  return null;
}

function isProdStage() {
    var stage = getAppSetting('identifier');
    return stage != undefined && stage != null && stage === prodIdentifier;
}
