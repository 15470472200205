import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import { GlobalState } from '../../store';
import { TaskTable } from '../tasktrackingTable/Table';
import NewUserAlertBanner from '../newUserAlertBanner/NewUserAlertBanner';

export default function HomepageContent(props: GlobalState) {
  return (
    <Box className="taskTrackingTable-box">
      <Grid gridDefinition={[{ colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { xxs: 1 } }]}>
        <div>
          <NewUserAlertBanner />

          <TaskTable />
        </div>
      </Grid>
    </Box>
  );
}
