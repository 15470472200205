import Modal from '@amzn/awsui-components-react/polaris/modal';
import { setErrorVisible } from './actions';
import { ErrorModalState } from './reducer';
import { useSelector } from 'react-redux';
import store from '../../store';

export default function ErrorModalComponent() {
  const errorModalState: ErrorModalState = useSelector((state) => state['errorModal']);
  return (
    <Modal
      onDismiss={() => store.dispatch(setErrorVisible(false))}
      visible={errorModalState.errorVisible}
      closeAriaLabel="Close modal"
      header="Error Encountered"
    >
      {getErrorMessageOrDisplayDefault(errorModalState.errorMessage)}
    </Modal>
  );
}

function getErrorMessageOrDisplayDefault(errorMessage: string) {
  if (errorMessage) {
    return errorMessage;
  } else {
    return 'Please try refreshing this window or contact turing-team@amazon.com if the error persists.';
  }
}
