import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import {
  reducer as metaAuditsTableReducer,
  stateKey as metaAuditsTableStateKey,
  sagas as metaAuditsTableSagas,
} from './components/metaAuditsTable';
import {
  reducer as createTaskFormReducer,
  stateKey as createTaskFormStateKey,
  sagas as createTaskFormSagas,
} from './components/createTaskForm';
import {
  reducer as taskTrackingTableReducer,
  stateKey as taskTrackingTableStateKey,
  sagas as taskTrackingTableSagas,
} from './components/tasktrackingTable';
import {
  reducer as createErrorModalReducer,
  stateKey as errorModalStateKey
} from './components/errorModal';
import { MetaAuditsTableState } from './components/metaAuditsTable/reducer';
import { CreateTaskFormState } from './components/createTaskForm/reducer';
import { TaskTrackingState } from './components/tasktrackingTable/reducer';
import { ErrorModalState } from './components/errorModal/reducer';

export interface GlobalState {
  metaAuditsTable: MetaAuditsTableState;
  createTaskForm: CreateTaskFormState;
  taskTrackingState: TaskTrackingState;
  errorModalState: ErrorModalState;
}

export const reducers = combineReducers({
  [metaAuditsTableStateKey]: metaAuditsTableReducer,
  [createTaskFormStateKey]: createTaskFormReducer,
  [taskTrackingTableStateKey]: taskTrackingTableReducer,
  [errorModalStateKey]: createErrorModalReducer,
});

const sagaMiddleware = createSagaMiddleware({
});

const store = createStore(reducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(metaAuditsTableSagas);
sagaMiddleware.run(createTaskFormSagas);
sagaMiddleware.run(taskTrackingTableSagas);

export default store;
