const modulePrefix = 'taskTrackingTable/';

export const REQUEST_PAGINATED_TASKS = `${modulePrefix}REQUEST_PAGINATED_TASKS`;
export const RECEIVE_PAGINATED_TASKS = `${modulePrefix}RECEIVE_PAGINATED_TASKS`;
export const ERROR_LOADING_TASKS = `${modulePrefix}ERROR_LOADING_TASKS`;

export const requestPaginatedTasks = (getPaginatedTaskInput) => ({
  type: REQUEST_PAGINATED_TASKS,
  getPaginatedTaskInput,
});

export const receivePaginatedTasks = (taskInfoList) => ({
  type: RECEIVE_PAGINATED_TASKS,
  taskInfoList,
});

export const errorLoadingTasks = () => ({
  type: ERROR_LOADING_TASKS,
});