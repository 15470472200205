import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import { GlobalState } from '../../store';
import { CreationForm } from '../createTaskForm/CreationFormContainer';
import NewUserAlertBanner from '../newUserAlertBanner/NewUserAlertBanner';

// The content in the main content area of the App layout
export default function HomepageContent(props: GlobalState) {
  return (
    <Box className="taskCreationForm-box">
      <Grid gridDefinition={[{ colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { xxs: 1 } }]}>
        <div>
          <NewUserAlertBanner />

          <CreationForm />
        </div>
      </Grid>
    </Box>
  );
}
