import { MixedLineBarChartProps } from "@amzn/awsui-components-react/polaris/mixed-line-bar-chart";
import { BatchInfo } from "./table-config";

export interface barEntry {
    title: string,
    type: string,
    valueFormatter: any,
    data: any,
    color: string,
}
  
// function to help convert asin progress data to bar chart series
export const convertAsinProgressData = (asinProgress) => {
    const asinProgressData: barEntry[] = [];
    let barColor = "";

    const asinTotal = getTotalAsinNmber(asinProgress);
    for (var asinStatus in asinProgress) {
        //Set color for each asin progress based on Color Guide:
        //https://polaris.a2z.com/foundation/visual-foundation/data-vis-colors/
        if (asinStatus === 'COMPLETED' || asinStatus === 'COMPLETED_PENDING_METAAUDIT') {
            barColor = "#67a353";
        } else if (asinStatus === 'INVALID' || asinStatus === 'REJECTED') {
            barColor = "#ba2e0f";
        } else {
            barColor = "#3184c2";
        }

        //Some label names are too long and cause clipping issues, we will replace them in the visual aide
        const asinCount = asinProgress[asinStatus]
        if (asinStatus === 'COMPLETED_PENDING_METAAUDIT'){
            asinStatus = 'COMPLETED_PM';
        } else if (asinStatus === 'WAITING_ON_DEPENDENT_TAG'){
            asinStatus = 'WAITING_ON_DT';
        }

        //Add formatted asin progress to chart
        asinProgressData.push({
            title: asinStatus,
            type: "bar",
            valueFormatter: e => `${asinTotal * e}`,
            data: [
                { x: "", y: asinCount / asinTotal },
            ],
            color: barColor
        });
    }

    return asinProgressData as Array<MixedLineBarChartProps.BarDataSeries<string>>;
};

export const getTotalAsinNmber = (asinProgress) => {
    let asinProgressSum = 0;
    for (const asinStatus in asinProgress) {
        asinProgressSum += asinProgress[asinStatus];
    }
    return asinProgressSum;
}

export const transformBatchInfo = (batchInfo) => {
    const batchInfoData: BatchInfo[] = [];

    for (const batch in batchInfo) {
        const batchInfoJson = JSON.parse(batchInfo[batch]);
        let link = JSON.parse(batchInfoJson.TaggingToolSpecificTracker);
        if (link.annologTaskId) {
            link = link.annologTaskId;
        } else if (link.ezdiaSubmittedFileLocation) {
            link = link.ezdiaSubmittedFileLocation;
        }

        batchInfoData.push({
            BatchId: batchInfoJson.BatchId,
            Vendor: batchInfoJson.TaggingTool,
            Link: link,
        });
    }

    return batchInfoData;
}