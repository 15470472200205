import axios, { AxiosInstance } from 'axios';
import { newClient } from '@amzn/midway-identity-java-script-client/dist/client';

export function* createApiClient() {
    const appRaw = yield axios('/settings.json');

    const client: AxiosInstance | any = newClient({
        httpEndpoint: {
            url: appRaw.data.javaBackendApiUrl
        },
        httpSigning: {
            scheme: appRaw.data.scheme,
            region: appRaw.data.region,
            service: appRaw.data.service
        },
        midwayIdentity: { cognitoIdentityPoolId: appRaw.data.cognitoIdentityPoolId },
    });
    client.defaults.headers.common = {};
    client.defaults.headers.post = {};

    return client;
}