const modulePrefix = 'errorModal/';

export const SET_ERROR_VISIBLE = `${modulePrefix}SET_ERROR_VISIBLE`;
export const SET_ERROR_MESSAGE = `${modulePrefix}SET_ERROR_MESSAGE`;

export const setErrorVisible = (value) => ({
  type: SET_ERROR_VISIBLE,
  value,
});

export const setErrorMessage = (value) => ({
  type: SET_ERROR_MESSAGE,
  value,
});
