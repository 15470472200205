import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { requestPaginatedTasks } from './actions';
import { TaskTrackingState } from './reducer';
import { COLUMN_DEFINITIONS, EmptyState, FILTERING_PROPERTIES, paginationLabels, PROPERTY_FILTERING_I18N_CONSTANTS } from './table-config';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import { getFilterCounterText } from '../../common/tableCounterStrings';

interface StateProps {
  taskTrackingState: TaskTrackingState;
}

interface DispatchProps {
  requestPaginatedTasks: (GetPaginatedTaskRequest) => void;
}

export function TaskTableContent(props: StateProps & DispatchProps & RouteComponentProps) {
  function requestAllTasks(){
    const taskStatuses = [
      'ACCEPTED',
      'PENDING',
      'PENDING_RESULT_GENERATION',
      'PENDING_META_AUDIT',
      'COMPLETED',
      'REJECTED',
      'ABORTED',
    ];
    taskStatuses.forEach((status) =>
      props.requestPaginatedTasks({
        taskStatus: status,
        lastEvaluatedKey: null,
      })
    );
  }

  const refreshButton = <Button variant="icon" iconName="refresh" onClick={() => requestAllTasks() } />;

  useEffect(() => {
    requestAllTasks()
  }, []);

  //Sort and filter displayed table items
  const { items, actions, paginationProps, propertyFilterProps, filteredItemsCount, collectionProps } = useCollection(
    props.taskTrackingState.tasks || [],
    {
      propertyFiltering: {
        filteringProperties: FILTERING_PROPERTIES,
        empty: (
          <EmptyState
            title="No tasks found"
            subtitle="Please refresh this table to try loading tasks again."
            action={refreshButton}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: {
        pageSize: 10,
      },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS[6]
        },
      },
      selection: {},
    }
  );

  return (
    <div>
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        loading={props.taskTrackingState.loading}
        loadingText="Loading tasks"
        filter={
          <PropertyFilter
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
            {...propertyFilterProps}
            countText={getFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        items={items}
        header={
          <Header
            counter={
              props.taskTrackingState.tasks &&
              `(${props.taskTrackingState.tasks.length})`
            }
          >
            Tracking Tasks
          </Header>
        }
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    taskTrackingState: state.taskTrackingTable,
  };
};

const mapDispatchToProps = {
  requestPaginatedTasks
};

export const TaskTable = connect(mapStateToProps, mapDispatchToProps)(withRouter(TaskTableContent));
