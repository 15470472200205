import { AxiosInstance } from 'axios';
import { put, takeEvery, all } from 'redux-saga/effects';
import { createApiClient } from '../../common/apiClient';
import { setErrorVisible, setErrorMessage } from '../errorModal/actions';
import * as actions from './actions';
import store from '../../store';
import { GetPaginatedTaskInput } from './reducer';

async function getPaginatedTasksResponse(client: AxiosInstance, payload: GetPaginatedTaskInput) {
  const response = await client.post('/fetchPaginatedTasks', payload);

  return response.data;
}

function* getPaginatedTasks(input) {
  const client = yield createApiClient();

  try {
    //We query the DDB until last evaluated key is null, do-while is needed since
    // in the first api call last evaluated key will always be null
    var getPaginatedTaskInput: GetPaginatedTaskInput = input.getPaginatedTaskInput;

    do {
      const data = yield getPaginatedTasksResponse(client, getPaginatedTaskInput);
      getPaginatedTaskInput.lastEvaluatedKey = data.lastEvaluatedKey;
      yield put(actions.receivePaginatedTasks(data.taskInfoList));
    } while (getPaginatedTaskInput.lastEvaluatedKey != null);
  } catch (e) {
    console.error(e);
    store.dispatch(setErrorVisible(true));
    store.dispatch(
      setErrorMessage(
        'Fetching tasks failed, please try reloading the page. If the error persists please contact @turing-team.'
      )
    );
    store.dispatch(actions.errorLoadingTasks());
  }
}

export default function* sagas() {
  yield all([takeEvery(actions.REQUEST_PAGINATED_TASKS, getPaginatedTasks)]);
}
