import Box from '@amzn/awsui-components-react/polaris/box';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { ReactNode } from 'react';
import { Link } from '@amzn/awsui-components-react';
import { TaskInfo } from './reducer';
import TrackingBar from './TrackingBar';
import { transformBatchInfo } from './helperFuncs';

const dateSorter = (item1: TaskInfo, item2: TaskInfo): number => {
  return item2.CreationTime.localeCompare(item1.CreationTime);
};

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<TaskInfo>[] = [
  {
    id: 'TaskName',
    header: 'Task Name',
    cell: (item) => <ExpandableSection header={item.TaskName}>
          <Box className="batchInfo-box"><Table columnDefinitions={COLUMN_DEF_SUBTABLE} items={transformBatchInfo(item.BatchInfo)}/></Box>
    </ExpandableSection>,
    minWidth: '600px',
  },
  {
    id: 'AsinProgress',
    header: 'Task Progress',
    cell: (item) => <TrackingBar {...item.AsinTracking} />,
    minWidth: '200px',
  },
  {
    id: 'TaskId',
    header: 'Task Id',
    cell: (item) => item.TaskId,
  },
  {
    id: 'TaskType',
    header: 'Task Type',
    cell: (item) => item.TaskType,
  },
  {
    id: 'TaskStatus',
    header: 'Task Status',
    cell: (item) => item.TaskStatus,
  },
  {
    id: 'RequesterId',
    header: 'Requester Id',
    cell: (item) => item.RequesterId,
  },
  {
    id: 'CreationDate',
    header: 'Creation Date',
    sortingComparator: dateSorter,
    cell: (item) => item.CreationTime.substring(0,10),
  },
  {
    id: 'Marketplace',
    header: 'Marketplace',
    cell: (item) => item.Marketplace,
  },
];

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

export const SEARCHABLE_COLUMNS = [
  'TaskName',
  'TaskId',
  'TaskType',
  'TaskStatus',
  'RequesterId',
  'CreationDate',
  'Marketplace'
];

export const FILTERING_PROPERTIES: any[] = [
  {
    propertyLabel: 'Task Name',
    key: 'TaskName',
    groupValuesLabel: 'Task Name',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Task Id',
    key: 'TaskId',
    groupValuesLabel: 'Task Id',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Task Type',
    key: 'TaskType',
    groupValuesLabel: 'Task Type',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Task Status',
    key: 'TaskStatus',
    groupValuesLabel: 'Task Status',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Requester Id',
    key: 'RequesterId',
    groupValuesLabel: 'Requester Id',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Creation Date',
    key: 'CreationDate',
    groupValuesLabel: 'Creation Date',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Marketplace',
    key: 'Marketplace',
    groupValuesLabel: 'Marketplace',
    operators: [':', '!:', '=', '!='],
  },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',

  filteringPlaceholder: 'Search',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',

  operationAndText: 'and',
  operationOrText: 'or',

  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',

  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',

  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: text => `Use: "${text}"`,
};

const COLUMN_DEF_SUBTABLE: TableProps.ColumnDefinition<BatchInfo>[] = [
  {
    id: 'BatchId',
    header: 'Batch Id',
    cell: (item) => item.BatchId,
    minWidth: 20,
  },
  {
    id: 'Vendor',
    header: 'Vendor',
    cell: (item) => item.Vendor,
    minWidth: 20,
  },
  {
    id: 'Link',
    header: 'Link',
    cell: (item) => <Link href={item.Link}>{item.Link}</Link>,
    minWidth: 20,
  },
];

export interface BatchInfo {
  BatchId: string;
  Vendor: string;
  Link: string;
}