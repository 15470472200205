import Alert from '@amzn/awsui-components-react/polaris/alert';

// A standard banner for new CTMS pages to provide access to our wiki
export default function NewUserAlertBanner() {
  return (
    <div className="alert-padding">
      <Alert header="CTMS New User Guide">
        Information about the tool and FAQs can be accessed through our{' '}
        {
          <a href="https://w.amazon.com/bin/view/TuringTeam/NewHome/CTMS/" target="_blank" rel="noopener noreferrer">
            provided wiki.
          </a>
        }{' '}
      </Alert>
    </div>
  );
}
